import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Title from "../components/title";
import Profil from "../images/profil.jpg"
import Bg from "../images/fitness-bg.jpg"

const Omne = ({data}) => (
    <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>O mně | Spark Performance</title> 
    </Helmet>
      <Layout>
   
      <Title title={'Profil'}  />
   
  <div>
    <img class="h-32 w-full object-cover lg:h-48" src={Bg} alt="" />
  </div>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
      <div class="flex">
        <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={Profil} alt="" />
      </div>
      <div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
        <div class="sm:hidden md:block mt-6 min-w-0 flex-1">
          <h1 class="text-4xl font-bold text-red-600 truncate">
            Jan Jiskra
          </h1>

      
        </div>
        <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
         
        </div>
      </div>
    </div>
    <div class="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
      <h1 class="text-4xl font-bold text-red-600 truncate">
        Jan Jiskra
      </h1>
    </div>
  </div> 

     
<main class="mt-5  pb-8">
    <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="sr-only">O mně</h1>
      
      <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
       
        <div class="grid grid-cols-1 gap-4 lg:col-span-2 order-2 lg:order-none">
          <section aria-labelledby="section-1-title">
            <h2 class="sr-only" id="section-1-title">Certifikace</h2>
            <div class="rounded-lg bg-white overflow-hidden shadow   p-6">
               <div className="prose text-gray-500 max-w-none">
               {data?.scomos?.cert?.content && 
                <div dangerouslySetInnerHTML={{__html:  data?.scomos?.cert?.content}}>
                </div>
              }
              
                     
                        
               </div>            
            </div>
          </section>
        </div>

       
        <div class="grid grid-cols-1 gap-4 order-1 lg:order-none">
          <section aria-labelledby="section-2-title">
            <h2 class="sr-only" id="section-2-title">O mně</h2>
            <div class="rounded-lg bg-white overflow-hidden shadow">
              <div class="p-6">
              <div className=" prose prose-indigo  text-gray-500 mx-auto">
              {data?.scomos?.omne?.content && 
                <div dangerouslySetInnerHTML={{__html:  data?.scomos?.omne?.content}}>
                </div>
              }
              
              </div>      
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
    </Layout>
  </>
)
 
export default Omne

export const query = graphql`
  query CertifikaceAndOmne {
    scomos {
      cert: pageItemFindOne(id:56) {
          content
      },
      omne: pageItemFindOne(id:57) {
          content
      }
    }
  }
` 